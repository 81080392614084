import Vue from 'vue'
import type { Route } from 'vue-router'
import Router from 'vue-router'
import { Component } from 'vue-property-decorator'
import TemplateTestPage from '@/pages/TemplateTestPage.vue'
import TestPage from '@/pages/TestPage.vue'

Vue.use(Router)

// Lazy loading filter see https://router.vuejs.org/guide/advanced/lazy-loading.html

const RedirectReportsView = () => import('@/pages/_site/RedirectReportsView.vue')
const RedirectOptimizationsView = () => import('@/pages/_site/RedirectOptimizationsView.vue')
const ScadaView = () => import(/* webpackChunkName: "scada" */ '@/pages/_site/ScadaView.vue')
const AlertView = () => import(/* webpackChunkName: "alerts" */ '@/pages/_site/AlertView.vue')
const AlertHistoryView = () =>
  import(/* webpackChunkName: "history" */ '@/pages/_site/SiteHistoryView.vue')
const TrendView = () => import(/* webpackChunkName: "trends" */ '@/pages/_site/TrendView.vue')
const AnalyticsView = () =>
  import(/* webpackChunkName: "analyticsView" */ '@/pages/_site/AnalyticsView.vue')
const MeterEvaluationView = () =>
  import(/* webpackChunkName: "meters" */ '@/pages/_site/MeterEvaluationView.vue')
const SiteTopologyView = () =>
  import(/* webpackChunkName: "topology" */ '@/pages/_site/SiteTopology.vue')
const SiteRecommendations = () =>
  import(/* webpackChunkName: "recommendations" */ '@/pages/_site/SiteRecommendations.vue')
const DigitalTwinView = () =>
  import(/* webpackChunkName: "twins" */ '@/pages/_site/DigitalTwinView.vue')
const HeatNetwork = () =>
  import(/* webpackChunkName: "heat-network" */ '@/pages/_site/HeatNetwork.vue')
const SiteList = () => import(/* webpackChunkName: "site-list" */ '@/pages/SiteListPage.vue')
const Dashboard = () => import(/* webpackChunkName: "dashboard" */ '@/pages/DashboardPage.vue')
const NotFound = () => import(/* webpackChunkName: "not-found" */ '@/pages/NotFoundPage.vue')
const Site = () => import(/* webpackChunkName: "site" */ '@/pages/SitePage.vue')
const SiteOverview = () =>
  import(/* webpackChunkName: "site-details" */ '@/pages/_site/SiteDetailView.vue')
const UserManagement = () =>
  import(/* webpackChunkName: "user-management" */ '@/components/settings/UserManagement.vue')
const SiteManagement = () =>
  import(/* webpackChunkName: "site-management" */ '@/pages/SiteManagementPage.vue')
const SubscriptionPage = () =>
  import(
    /* webpackChunkName: "alert-subscriptions" */ '@/components/settings/NotificationSettings.vue'
  )
const NotificationCenter = () =>
  import(/* webpackChunkName: "notification-center" */ '@/pages/NotificationCenterPage.vue')
const RemoteAccessView = () =>
  import(/* webpackChunkName: "remote-access" */ '@/pages/_site/RemoteAccessView.vue')

const UnsubscribePage = () =>
  import(/* webpackChunkName: "unsubscribe" */ '@/pages/UnsubscribePage.vue')

const SettingsPage = () =>
  import(/* webpackChunkName: "settings-page" */ '@/pages/SettingsPage.vue')

const GeneralSettings = () =>
  import(/* webpackChunkName: "general-settings" */ '@/components/settings/GeneralSettings.vue')

Component.registerHooks(['beforeRouteEnter', 'beforeRouteUpdate', 'beforeRouteLeave'])

const router = new Router({
  routes: [
    {
      path: '/',
      name: 'Dashboard',
      component: Dashboard,
    },
    {
      path: '/sites',
      name: 'SiteList',
      component: SiteList,
    },
    {
      path: '/site/:id',
      component: Site,
      name: 'SitePage',
      props: true,
      children: [
        {
          path: '/',
          redirect: { name: 'SiteOverview' },
        },
        {
          props: true,
          path: 'overview',
          name: 'SiteOverview',
          component: SiteOverview,
        },
        {
          path: 'scada',
          props: true,
          name: 'SiteScada',
          component: ScadaView,
        },
        {
          props: true,
          path: 'trends',
          name: 'SiteCharts',
          component: TrendView,
        },
        // Trends have once been called charts, this was not adjusted in the URL. To prevent confusion for
        // existing users (e.g. bookmarks that do not work anymore). We keep a redirect for now. (14.03.2022, current release: 2.3.3)
        {
          props: true,
          path: 'charts',
          redirect: 'trends',
        },
        {
          props: true,
          path: 'alerts',
          name: 'SiteAlerts',
          component: AlertView,
        },
        {
          path: 'alert-history',
          name: 'SiteAlertHistory',
          component: AlertHistoryView,
          props: true,
        },
        {
          path: 'analytics',
          name: 'SiteAnalytics',
          component: AnalyticsView,
          props: true,
        },
        {
          path: 'meters',
          name: 'MeterEvaluation',
          component: MeterEvaluationView,
          props: true,
        },
        {
          path: 'topology',
          name: 'SiteTopology',
          component: SiteTopologyView,
          props: true,
        },
        {
          path: 'recommendations',
          name: 'SiteRecommendations',
          component: SiteRecommendations,
          props: true,
        },
        {
          path: 'twins',
          name: 'DigitalTwins',
          props: true,
          component: DigitalTwinView,
        },
        {
          path: 'network',
          name: 'HeatNetwork',
          props: true,
          component: HeatNetwork,
        },
        {
          path: 'remote-access',
          name: 'RemoteAccess',
          props: true,
          component: RemoteAccessView,
        },
        {
          path: 'reports',
          name: 'Reports',
          props: true,
          component: RedirectReportsView,
        },
        {
          path: 'optimizations',
          name: 'Optimizations',
          props: true,
          component: RedirectOptimizationsView,
        },
      ],
    },
    {
      name: 'Settings',
      path: '/settings',
      component: SettingsPage,
      redirect: '/settings/general',
      children: [
        {
          name: 'GeneralSettings',
          path: 'general',
          component: GeneralSettings,
        },
        { name: 'NotificationSettings', path: 'notifications', component: SubscriptionPage },
        {
          path: 'users',
          component: UserManagement,
          name: 'UserManagement',
        },
        {
          path: 'manage-sites',
          component: SiteManagement,
          name: 'SiteManagement',
        },
      ],
    },
    {
      path: '/notifications/',
      component: NotificationCenter,
      name: 'NotificationCenter',
    },

    {
      path: '/unsubscribe/:siteId',
      component: UnsubscribePage,
      name: 'Unsubscribe',
      props: true,
    },
    {
      path: '*',
      component: NotFound,
    },
    {
      name: 'test',
      path: '/test/:id',
      component: TestPage,
    },
    { name: 'template-test', path: '/templates', component: TemplateTestPage },
  ],
})
router.afterEach((to: Route) => {
  // Update the page title when switching to another page
  const baseTitle = Vue.i18n.translate('base_page_title') as string
  const keyForPage = 'page_title_' + to.name
  if (Vue.i18n.keyExists(keyForPage)) {
    document.title = `${baseTitle} - ${Vue.i18n.translate(keyForPage) as string}`
  } else {
    document.title = baseTitle
  }
})

export default router
