import { scadaTranslationsEn } from '@/languages/scada/scada.en'
import { chartTranslationsEn } from '@/languages/trends/trends.en'
import { alertTranslationsEn } from '@/languages/alerts/alerts.en'
import { analyticsTranslationsEn } from '@/languages/analytics/analytics.en'
import { userManagementTranslationsEn } from '@/languages/user-management/user-management.en'
import { siteManagementTranslationsEn } from '@/languages/site_management/site_management.en'
import { meterTranslationsEn } from '@/languages/meters/meters.en'
import { topologyTranslationsEn } from '@/languages/topology/topology.en'
import { TwinTranslationsEn } from '@/languages/twins/twins.en'
import { HeatNetworkTranslationsEn } from '@/languages/heat_networks/heat_networks.en'
import { notificationCenterEn } from '@/languages/notification_center/notification_center.en'
import { RemoteAccessTranslationsEn } from '@/languages/remote_access/remote_access.en'
import { recommendationsEn } from '@/languages/recommendations/recommendations.en'

export const translationsEn = {
  ...scadaTranslationsEn,
  ...chartTranslationsEn,
  ...alertTranslationsEn,
  ...analyticsTranslationsEn,
  ...userManagementTranslationsEn,
  ...siteManagementTranslationsEn,
  ...meterTranslationsEn,
  ...topologyTranslationsEn,
  ...TwinTranslationsEn,
  ...HeatNetworkTranslationsEn,
  ...notificationCenterEn,
  recommendations: recommendationsEn,
  lpb_time_program_editor: {
    on_label: 'on',
    off_label: 'off',
    active_label: 'Active',
  },
  redirect_reports: "Reports",
  redirect_optimizations: "Optimizations",
  remote_access: RemoteAccessTranslationsEn,
  hello_world: 'Hello World!',
  manage_account: 'Manage account',
  request_support: 'Request support',
  sign_out: 'Sign out',
  imprint: 'Imprint',
  privacy: 'Privacy',
  base_page_title: 'othermo Portal',
  page_title_home: 'Home',
  page_title_about: 'About',
  site: 'Site',
  sites_per_page: 'Sites per page',
  diagram: 'Diagram',
  schema: 'Plant Schematics',
  page_not_found: '404 - Page not found',
  sth_went_wrong: 'Something went wrong.',
  warning: 'Warning:::Warnings',
  error: 'Error:::Errors',
  goto_site: 'Details',
  all_sites: 'All Sites',
  site_details: 'Site Details',
  location: 'Location',
  connection_state: 'Connection status',
  status_unknown: 'Unknown',
  status_connected: 'Connected',
  status_disconnected: 'Disconnected',
  status_timeout: 'Timed out',
  status_denied: 'Access denied',
  on: 'On',
  off: 'Off',
  enter_chars: 'Enter at least {length} characters',
  enter_max_chars:
    'Your input is too long and will not be properly displayed. ' +
    'Enter not more than {length} characters',
  last_update: 'Last updated {seconds}s ago.',
  last_update_never: 'Last Update: Never',
  overview: 'Overview',
  diagrams: 'Diagrams',
  delete_svg: 'Delete SVG?',
  confirm_delete: 'Please confirm deletion of the SVG {title}',
  delete: 'Delete',
  cancel: 'Cancel',
  edit: 'Edit',
  overview_state: 'Current State',
  connection_status_unknown_description: 'The current connection status cannot be retrieved',
  connection_status_denied_description: 'The access has been denied',
  connection_status_timeout_description: 'Connection lost',
  connection_status_disconnected_description: 'The site is currently disconnected',
  connection_status_connected_description: 'The site is connected',
  numeric_out_of_range: 'The number has to be between {min} and {max}',
  dropdown_please_select: '-- Please select an option --',
  edit_item_value_changed:
    'The value of the property {propertyName} has changed. Your input was overridden.',
  no_summary: 'No summary available',
  summary: 'Summary',
  summary_name: 'Name',
  summary_value: 'Value',
  summary_setpoint: 'Setpoint',
  summary_add_item: 'Add row',
  summary_empty: 'Summary is empty',
  summary_save_success: 'Summary saved successfully',
  summary_save_error: "Couldn't save summary: {error}",
  summary_edit: 'Edit summary',
  summary_loading_error: 'Could not load data point',
  action_error_not_implemented: 'The requested operation is not implemented.',
  action_error_unknown: 'Unknown error: {error}',
  action_error_server_error: 'The action throw an error: {error}',
  action_error_not_found: 'Error: Thing not found.',
  action_error_denied: 'Error: The user is not authorized to send this message',
  action_error_bad_req: 'Error: Bad method parameters: {error}',
  action_error_timeout: 'Error: Could not deliver the command. [Timeout]',
  action_success: 'Success',
  close: 'Close',
  unsaved_changes: 'You have unsaved changes',
  apply: 'Apply',
  'thingId: %s': 'Id of thing: %s',
  'othermo:DO:0.1.0': 'English value translation',
  create: 'Create',
  map_view: 'Map view',
  grid_view: 'Grid view',
  alarm_view: 'Alarm view',
  reports_view: 'Reports',
  site_marker_ok: 'Connected, no errors, no warnings',
  site_marker_warning: 'Connected, at least one warning',
  site_marker_error: 'Connected, at least one error',
  site_marker_critical: 'Connected, at least one critical error',
  site_marker_unknown: 'Status unknown',
  site_marker_no_data: 'No recent data available',
  undefined_locations:
    '{count} site not shown due to missing location data:::{count} sites not shown due to missing location data',
  search: 'Search',
  loading: 'loading...',
  save: 'Save',
  error_unsupported_browser:
    'Due to a bug in Firefox, no data can be shown. Please either use a current version of Chrome or Edge, or update Firefox to version 85.0 or later',
  letter_north: 'N',
  letter_south: 'S',
  letter_east: 'E',
  letter_west: 'W',
  notice_staging_env:
    'You are currently working in a staging or development environment, changes might not be applied to production!',
  attention: 'Attention',
  ID: 'ID',
  id: 'ID',
  Id: 'ID',
  date: 'Date',
  time: 'Time',
  expand: 'Expand',
  collapse: 'Collapse',

  app: {
    new_version: {
      toast_tile: 'New version released! 🎉',
      toast_subtitle: 'We added some new features to the portal',
      go_to_changelog: 'Go to full changelog',
    },
    settings: 'Settings',
  },
  grid: {
    title: 'Grid',
  },
  map: {
    title: 'Map',
  },
  settings: {
    title: 'Settings',
    general: {
      title: 'General',
      reload_translations_success: 'Translation reloaded successfully',
      reload_translations_failed: 'Could not reload translation',
      reload_translations: 'Reload translations',
      language: {
        title: 'Language',
        description: 'Choose a language for the Portal',
      },
    },
    notifications: 'Notifications',
  },
  dashboard: {
    title: 'Dashboard',
    map: {
      toggle_legend: 'Toggle legend',
      show_all_sites: 'Zoom to all sites',
      fullscreen: 'Fullscreen',
      sites_without_location: '{count} sites without specific location',
    },
    filter: {
      by_severity: 'Severity',
      by_maintenance_mode: 'Maintenance Mode',
      by_connection_status: 'Connection Status',
    },
    stats: {
      sites_online: 'Sites online',
      sites_offline: 'Sites offline',
      with_warning: 'with Warnings',
      with_error: 'with Errors',
      with_critical: 'with Critical Errors',
      sites_total: 'Sites total',
    },
    alarm: {
      filter_by_site: 'Filter by site',
      maintenance_filter: {
        maintenance_status: 'Maintenance Mode',
        maintenance_mode: 'Sites in maintenance mode',
        not_maintenance_mode: 'Sites not in maintenance mode',
      },
      no_active_alarms_to_display:
        'No site with active alarms according to your selected severities',
    },
    error_loading_site_alarms: 'Could not load alarms of site',
    error_loading_sites: 'Could not load sites',
    error_loading_site_muteStatus: 'Mute setting mode of a site could not be loaded',
    no_site_name: 'Site without name',
    site_list_update_error: 'Could not update list of sites: {error}',
    nothing_here: 'Nothing here (yet)',
    nothing_here_helpline:
      "Either you don't have permissions to view any sites or all sites have been hidden. Please contact your administrator if the problem persists",
    nothing_here_filter: 'Nothing here',
    no_site_fulfills_filter_options:
      'No site fulfills the filter options, reset the current filters to display sites.',
    sort: {
      sortBy: 'Sort by: ',
      createdAscending: 'Created (ascending)',
      createdDescending: 'Created (descending)',
      nameAscending: 'Name (ascending)',
      nameDescending: 'Name (descending)',
      severity: 'Severity',
    },
    search_for_site: 'Search for site',
    expand_notifications: 'Expand notifications',
    collapse_notifications: 'Collapse notifications',
  },
  menu: {
    oss_licenses: 'Open source licenses',
  },
  type: 'Type',
  show_more: 'Show more',
  discard_changes_short: 'Discard changes',
  reports: {
    title: 'Reports',
    operation: 'Operations',
    energy: 'Energy',
    timespan: 'Time',
    startTime: 'Start',
    endTime: 'End',
    error_loading_report: 'Error when loading report: {error}',
    cannot_display: "Can't display report",
    error_cause: 'Cause',
    not_available: 'Report not available',
    time: {
      last_month: '1 Month',
      last_year: '1 Year',
      current_year_to_date: 'Current year to date',
      custom: 'Custom',
    },
  },
  unsubscribe: {
    title: 'Unsubscribe from Notifications',
    from_site: 'Unsubscribe from notifications for site "{siteName}"',
    from_all: 'Unsubscribe from all notifications',
    mute_site: 'Mute site',
    mute_alert: 'Mute alert',
    notification_settings_saved: 'Notification settings saved',
    referenced_alert: 'Alert',
    alert_muted: 'Alert muted',
    alert_unmuted: 'Alert unmuted',
    error: {
      saving_notification_settings: 'Could not save notification settings',
      toggling_alert_mute: 'Could not mute/unmute alert',
    },
  },
}
